<template>
  <div>
    <div class="flex-between">
      <div class="title">{{ $t('accountInformation') }}</div>
      <div class="flex-row">
        <!-- <i class="el-icon-message message" style="margin: 0 10px"></i>
        <span class="msg-num">0</span>-->
      </div>
    </div>
    <div class="flex-between info">
      <div class="flex-row">
        <img
          class="verify-img"
          style="width: 70px; height: 70px; border-radius: 50%"
          :src="companyLogo"
        />
        <div class="info-text">
          <span>{{ $t('name') }}{{ supplyInfo.username }}</span>
          <span style="margin-left: 200px"
            >{{ $t('state')
            }}{{
              supplyInfo.approved === auth.UNKNOWN.value
                ? $t('responseStatus.unknown')
                : supplyInfo.approved === auth.CONFIRM.value
                ? $t('responseStatus.confirm')
                : supplyInfo.approved === auth.PROCESSING.value
                ? $t('responseStatus.processing')
                : supplyInfo.approved === auth.REJECT.value
                ? $t('responseStatus.reject')
                : $t('responseStatus.unknown')
            }}</span
          >
          <!-- <div>{{ $t("identity") }}{{ supplyInfo.symbol === symbol.ORGANIZATION.value ? $t('firstSupplier') : $t('firstSupplier') }}</div> -->
        </div>
      </div>
      <div style="height: 70px; line-height: 70px; float: right">
        <el-button class="btn-black" @click="toDetails">{{
          $t('see')
        }}</el-button>
      </div>
    </div>
    <div class="title">{{ $t('financingProducts') }}</div>
    <div class="flex-row pro-view">
      <img src="../../static/img/orders-receivable.png" />
      <div class="order">
        <div>{{ $t('orderFinancing') }}</div>
        <div>{{ $t('onlyApplyForFinancing') }}</div>
      </div>
      <div style="text-algin: right">
        <el-button
          class="btn-black"
          @click="$router.push({ path: '/assets-list/assets-finance' })"
          >{{ $t('applyForFinancing') }}</el-button
        >
      </div>
    </div>
    <div class="title">{{ $t('titleI18n.AccountWallet') }}</div>
    <div class="flex-between info">
      <div>
        {{ $t('unitAmountColumn') }}
        <span class="msg-num">{{
            supplyInfo.unitToken | toThousandFilterTwo
        }}</span
        >({{ $t('dollar') }})
      </div>
      <div class="info-text">
        <el-button
          class="btn-black"
          @click="$router.push({ path: '/supply-tr/withdrawal' })"
          >{{ $t('btnI18n.Withdrawal') }}</el-button
        >
      </div>
    </div>
    <div class="title">{{ $t('repaymentStatistics') }}</div>
    <div class="flex-between top-view">
      <div class="flex-column-center flex-wrap">
        <div>{{ $t('outstandingAmount') }} ({{ $t('millionDollars') }})</div>
        <div class="text-org">{{ supplyInfo.repayAmount | toThousandFilterTwo }}</div>
      </div>
      <div class="flex-column-center flex-wrap">
        <div>{{ $t('numberOutstandingTransactions') }}</div>
        <div class="text-org">{{ supplyInfo.repayCount }}</div>
      </div>
    </div>
    <div class="title">{{ $t('financingInformation') }}</div>
    <div class="flex-between">
      <div class="flex-column-center flex-wrap">
        <div>
          {{ $t('totalHistoricalFinancingAmountCompleted') }} ({{
            $t('millionDollars')
          }})
        </div>
        <div class="text-org">{{ supplyInfo.clearedAomunt | toThousandFilterTwo }}</div>
      </div>
      <div class="flex-column-center flex-wrap">
        <div>{{ $t('historicalNumberFinancingTransactionCompleted') }}</div>
        <div class="text-org">{{ supplyInfo.clearedCount }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import NP from 'number-precision';
import filter from '@/filters';
export default {
  data() {
    return {
      symbol: this.$enums.INVESTORTYPE,
      auth: this.$enums.AUTH,
      supplyInfo: {},
      outstandingAmount: 0,
      accountAmount: 0,
      numberOutstandingTransactions: 0,
      totalHistoricalFinancingAmountCompleted: 0,
      historicalNumberFinancingTransactionCompleted: 0,
      companyLogo: '',
    };
  },
  mounted() {
    // 获取公司logo
    this.getDetail();
    this.getSupplyInfo();
  },
  methods: {
    getDetail() {
      this.$axios
        .get('/v1/supplier/detail')
        .then((result) => {
          if (result.code === 0 || result.code === '0') {
            this.companyLogo = result.data.companyLogo;
          } else {
            this.$message({ type: 'error', message: result.message });
          }
        })
        .catch((error) => console.log(error));
    },
    getSupplyInfo() {
      this.$axios
        .get('/v1/supplier/overview')
        .then((result) => {
          if (result.code === 0 || result.code === '0') {
            this.supplyInfo = result.data;
            this.supplyInfo.repayAmount = this.supplyInfo.repayAmount
              ? (
                  Number(this.supplyInfo.repayAmount) / this.$enums.UNIT_MILLION
                ).toFixed(2)
              : '0.00';
            this.supplyInfo.clearedAomunt = this.supplyInfo.clearedAomunt
              ? (
                  Number(this.supplyInfo.clearedAomunt) /
                  this.$enums.UNIT_MILLION
                ).toFixed(2)
              : '0.00';
          } else {
            this.$message({ type: 'error', message: result.message });
          }
        })
        .catch((error) => console.log(error));
    },
    msgClick() {
      this.$message({
        showClose: true,
        message: this.$t('noMessageDeal'),
      });
    },
    toDetails() {
      this.$router.push({ path: '/supply-all/supplier-detail' });
    },
  },
};
</script>

<style lang="scss" scoped>
.message {
  font-size: 30px;
  color: #81d8d0;
}
.msg-num {
  color: #81d8d0;
  margin: 0 4px;
}
.info {
  border-bottom: 1px solid #979797;
  padding-bottom: 10px;
  .info-text {
    margin-left: 20px;
    line-height: 30px;
  }
}
.msg-num {
  color: #81d8d0;
}
.pro-view {
  padding: 30px 20px;
  border-radius: 5px;
  border: 1px solid #979797;
  .order {
    margin-left: 10px;
    font-size: 18px;
    flex: 1;
    > div:nth-child(1) {
      font-weight: 500;
    }
    > div:nth-child(2) {
      margin: 10px 0;
    }
    > div:nth-child(3) {
      font-size: 14px;
    }
  }
  .deal {
    color: #81d8d0;
    margin-top: 20px;
    margin-left: 4px;
    &:hover {
      cursor: pointer;
      color: #ef6c00;
    }
  }
}
.top-view {
  height: 90px;
  border-bottom: 1px solid #979797;
  margin-bottom: 20px;
  line-height: 30px;
}
.title span.tip {
  font-size: small;
  font-weight: normal;
}
</style>
